<template>
  <div>
    <div v-show="visible" class="info">
      <top-bar :title="!submitData.id?'新增协同任务':edit?'修改协同任务':'查看协同任务'" :left="true"></top-bar>
      <!-- 所在小区 -->
      <van-popup v-model="subareaShow" position="bottom" >
        <van-picker title="请选择" show-toolbar :columns="selectData.subareaList" value-key="name"
                    @confirm="subareaConfirm" @cancel="subareaShow = false"/>
      </van-popup>
      <!-- 预计完成时间 -->
      <van-popup v-model="preCompleteTimeShow" position="bottom" >
        <van-datetime-picker v-model="submitData.preCompleteTime" type="date" title="请选择" :formatter="formatDate"
                              @cancel="preCompleteTimeShow = false" @confirm="timeConfirm"/>
      </van-popup>

      <van-cell-group>
        <van-cell class="info-title">
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">协同任务</span>
          </template>
        </van-cell>
        <van-cell title="所在小区" :value="selectMsg.subarea" @click="subareaShow = edit" :clickable="edit"
                  :value-class="{'value-common':selectMsg.subarea=='请选择'}" :is-link="edit"  required/>
        <van-cell title="协同任务" required>
          <van-field clearable clear-trigger="always" v-model="submitData.projectTask" placeholder="请输入" :readonly="!edit"/>
        </van-cell>
        <van-cell title="具体任务" required>
          <van-field clearable clear-trigger="always" v-model="submitData.concretenessTask" placeholder="请输入" :readonly="!edit"/>
        </van-cell>
        <van-cell title="点数" required>
          <van-field clearable clear-trigger="always" type="digit" v-model="submitData.count" placeholder="请输入" :readonly="!edit"/>
        </van-cell>
        <van-cell title="预计完成时间" :value="selectMsg.preCompleteTime" @click="preCompleteTimeShow = edit"
                  :value-class="{'value-common':selectMsg.preCompleteTime=='请选择'}" :is-link="edit" required/>
      </van-cell-group>
<!--      <van-cell-group v-if="edit" class="info-second">-->
<!--        <van-cell title="是否需要上报问题" class="info-title" center>-->
<!--          <van-switch v-model="problemSubmit" size="24px" @change="problemSubmitShow"/>-->
<!--        </van-cell>-->
<!--      </van-cell-group>-->

      <template v-if="true">
        <van-cell-group v-for="(item, index) in submitData.childList" :key="index" class="info-second">
          <van-cell :title="'具体步骤'+(index+1)" class="info-title">
            <template v-if="edit">
              <van-image :src="require('@/assets/img/problem_add.png')" height="25" width="25" style="margin-right: 10px" @click="addStep"/>
              <van-image v-if="submitData.childList.length>1" :src="require('@/assets/img/problem_delete.png')" height="25" width="25" style="margin-right: 10px" @click="deleteStep(index)"/>
            </template>
            <van-image :src="require(`@/assets/img/problem_${childShow?'shrink':'open'}.png`)" height="25" width="25" @click="childShow=!childShow"/>
          </van-cell>
          <div v-show="childShow">

            <van-cell required title="具体步骤">
              <van-field clearable clear-trigger="always"  v-model="item.concretenessStep" placeholder="请输入具体步骤" :readonly="!edit"/>
            </van-cell>
<!--            <van-cell title="问题类型" :value="item.typeTitle" @click="checkProblemType(index)"-->
<!--                      :value-class="{'value-common':item.typeTitle=='请选择'}" :is-link="edit" />-->
            <van-field
              clearable
              clear-trigger="always"
              :border="false"
              class="left"
              v-model="item.accomplishCondition"
              rows="4"
              autosize
              type="textarea"
              placeholder="请输入完成情况描述"
              input-align="left"
              :readonly="!edit"
            />
            <van-cell title="是否已完成">
              <van-radio-group v-model="item.accomplish" direction="horizontal" >
                <van-radio :name="0">未完成</van-radio>
                <van-radio :name="1">完成</van-radio>
              </van-radio-group>
            </van-cell>
<!--            <van-row>-->
<!--              <van-col :span="24">-->
<!--                <van-uploader v-model="item.newfiles" @delete="uploaddelProblem" :name="index"-->
<!--                              :before-read="beforeUpload" :after-read="uploadReadProblem"/>-->
<!--              </van-col>-->
<!--            </van-row>-->
          </div>
        </van-cell-group>
      </template>

      <van-row class="btns">
        <van-col :span="24" v-if="edit">
          <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
        </van-col>
        <van-row gutter="10" v-else>
          <van-col :span="24">
            <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
          </van-col>
        </van-row>
      </van-row>
    </div>
<!--    <place-list v-if="placeListVisible" @checkPlace="checkPlace"></place-list>-->
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {newDate, formatterDate} from "@/utils/utils"
import {getImageStream} from '@/utils/index'
import placeList from "../visit/placeList"

export default {
  data() {
    return {
      i: '',
      visible: true,
      edit: false,
      childShow: true,
      file: {},
      detailData: {},
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      subareaShow: false,
      preCompleteTimeShow: false,
      selectMsg: {
        subarea:'请选择',
        preCompleteTime:'请选择',
      },
      submitData: {
        id: '',
        subarea:'',
        projectTask:'',
        concretenessTask:'',
        count:'',   //点数
        preCompleteTime:'',
        childList:[{id:"",concretenessStep: '',accomplishTime: '',accomplish: 0,accomplishCondition:''}],
        deleteIdList:[]
      },
      selectData: {

        subareaList: [],

      },
      fileList: [],
    }
  },
  components: {
    topBar,
    placeList
  },
  methods: {
    // 初始化
    init() {
      this.getSubareaList()
      let nowDate = new Date()
      this.maxDate = nowDate
      this.selectMsg.preCompleteTime = formatterDate(nowDate)
      this.submitData.preCompleteTime = nowDate
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }

    },
    // 获取小区列表
    getSubareaList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.subareaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/apptriplecotasking/info/'+this.submitData.id),
        method: 'post',
        params: this.$http.adornParams({
        })
      }).then(({data}) => {
        // debugger
        if (data && data.code === 0) {
          this.submitData.id= data.appTripleCotasking.id
          this.submitData.community= this.$orgId
          this.submitData.subarea=data.appTripleCotasking.subarea
          this.selectMsg.subarea=data.appTripleCotasking.subareaStr
          this.submitData.projectTask=data.appTripleCotasking.projectTask
          this.submitData.concretenessTask=data.appTripleCotasking.concretenessTask
          this.submitData.count=data.appTripleCotasking.count
          this.submitData.preCompleteTime=data.appTripleCotasking.accomplishTime
          this.selectMsg.preCompleteTime=data.appTripleCotasking.accomplishTime
          this.submitData.childList=data.appTripleCotasking.childList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 提交
    submit() {
      if (this.submitData.subarea === '') {
        return this.$toast.fail('失败:请选择所属小区');
      }
      if (this.submitData.projectTask === '') {
        return this.$toast.fail('失败:请输入项目任务');
      }
      if (this.submitData.concretenessTask === '') {
        return this.$toast.fail('失败:请输入具体任务');
      }
      if (this.submitData.count === '') {
        return this.$toast.fail('失败:请输入点数');
      }
      // debugger
      // 步骤校验
      if (this.submitData.childList.length != 0) {
        for (var i = 0; i < this.submitData.childList.length; i++) {
          if (!this.submitData.childList[i].concretenessStep) {
            return this.$toast.fail('失败:请输入具体步骤')
          }
          if (!this.submitData.childList[i].accomplishCondition) {
            return this.$toast.fail('失败:请输入完成后情况描述')
          }
          if (this.submitData.childList[i].accomplish==="") {
            return this.$toast.fail('失败:请选择是否完成')
          }

        }
      }
      let that= this
      this.submitData.childList.map(e=>{
        e.accomplishTime =that.submitData.preCompleteTime
      })
      this.submitData.content = this.selectMsg.description
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/apptriplecotasking/save`),
        method: 'post',
        data: this.$http.adornData({
          id: this.submitData.id,
          community: this.$orgId,
          subarea:this.submitData.subarea,
          projectTask:this.submitData.projectTask,
          concretenessTask:this.submitData.concretenessTask,
          count:this.submitData.count,
          childList:this.submitData.childList,
          deleteIdList:this.submitData.deleteIdList,
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subarea = value.name
        this.submitData.subarea = value.id
      }
      this.subareaShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.preCompleteTime = formatterDate(value)
        this.submitData.preCompleteTime = formatterDate(value)
      }
      this.preCompleteTimeShow = false
    },
    // 新增步骤
    addStep() {
      let child = {
        id:"",
        concretenessStep: '',
        accomplishTime: '',
        accomplish: 0,
        accomplishCondition:''
      }
      this.submitData.childList.push(child)
    },
    // 删除步骤
    deleteStep(i) {
      let that=this
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        var id = that.submitData.childList[i].id;
        if (id) {
          that.submitData.deleteIdList.push(id)
        }
        if (that.submitData.childList.length >= 1) {
          that.submitData.childList.splice(i, 1)
        }
      })
    },
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.init()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style lang="scss" scoped>
::v-deep .van-field__control {
  text-align: left !important;
}
::v-deep .left .van-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.radioSize{
  icon-size:100px
}
::v-deep .van-radio-group {
  float: right;
  padding-top: 4px;
  font-size: 26px;
  line-height: 48px;
  .van-radio__icon {
    height: 40px;
    width: 40px;
    font-size: 40px;
    .van-icon {
      height: 40px;
      width: 40px;
      &::before {
       height: 40px;
       width: 40px;
     }
    }
  }
}
</style>
